import groupby from 'lodash.groupby';
import strFormat from 'string-format';
import {
  startOfDay,
  subDays,
  startOfWeek,
  format,
  endOfDay,
  subWeeks,
  endOfWeek,
  getWeek,
  isSameDay,
  isAfter,
} from 'date-fns';
import { ComplianceCaseStatus, RiderStates } from 'utils/constants';
import { Employee } from 'models';

export const sortBy =
  (prop, order) =>
  (el1, el2): number =>
    order.indexOf(el1[prop]) - order.indexOf(el2[prop]);

export const sortKey = (key, type): string => {
  return key && type ? `${key}_${type}` : key || '';
};

export const filterByProperties = (text, props: string[]) => (el) => {
  const joined = props
    .map((prop) => el[prop] || '')
    .join('')
    .replace(/\s/g, '');
  return joined.toLowerCase().includes(text.replace(/\s/g, '').toLowerCase());
  // return props.some((prop) =>
  //   (el[prop] || '').toLowerCase().includes(text.toLowerCase())
  // );
};

export const delay = (ms): Promise<void> =>
  new Promise((resolve) => setTimeout(resolve, ms));

export const BIRTHDAY_REGEX =
  /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/;

export const BIRTHDAY_MASKED_DATE_OPTIONS = {
  pattern: 'YYYY-MM-DD',
  overwrite: true,
  format: (date: Date): any => {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();

    return [year, month, day].join('-');
  },
  parse: (str: any) => {
    const [year, month, day] = str.split('-');
    return new Date(year, month - 1, day);
  },
};

export const PHONE_NUMBER_REGEX = /^\+[1-9]\d{1,14}$/g;
export const ALPHA_REGEX = /^[a-zA-Z\s,.'\-\pL]+$/g;
export const EC_ID_REGEX = /^\d{1,8}$/g;
// export const ALPHA_REGEX = /^[\p{L} .'-]+$/gu;

export const isInThePast = (date): boolean => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  return date < today;
};

export const zeroPad = (num, places): string =>
  String(num).padStart(places, '0');

// TODO: check if this logic is correct or not
export const getComplianceCaseStatus = (cases): ComplianceCaseStatus | null => {
  const casesByStatus = groupby(cases, ({ status }) => status);
  if (casesByStatus[ComplianceCaseStatus.OPENED]?.length) {
    return ComplianceCaseStatus.OPENED;
  }
  if (casesByStatus[ComplianceCaseStatus.TERMINATION_REQUESTED]?.length) {
    return ComplianceCaseStatus.TERMINATION_REQUESTED;
  }
  if (casesByStatus[ComplianceCaseStatus.TERMINATION_APPROVED]?.length) {
    return ComplianceCaseStatus.TERMINATION_APPROVED;
  }
  return null;
};

export const getTimeNow = (): string => {
  const now = new Date();
  return `${String(now.getHours()).padStart(2, '0')}:${String(
    now.getMinutes()
  ).padStart(2, '0')}`;
};

const getSortIndex = (status: RiderStates, reason?: string): number => {
  if (status === RiderStates.ONLINE) {
    return 1;
  }
  if (status === RiderStates.RETURNING) {
    return 2;
  }
  if (status === RiderStates.BUSY) {
    return 3;
  }
  if (status === RiderStates.TEMP_OFFLINE && reason) {
    return 4;
  }
  if (status === RiderStates.IDLE) {
    return 5;
  }
  if (status === RiderStates.TEMP_OFFLINE && !reason) {
    return 6;
  }
  if (status === RiderStates.ON_BREAK) {
    return 7;
  }
  if (status === RiderStates.STARTING) {
    return 8;
  }
  return 9;
};

export const sortRidersByState = (r1: Employee, r2: Employee): number => {
  return (
    getSortIndex(r1?.status, r1?.statusReason) -
    getSortIndex(r2?.status, r2?.statusReason)
  );
};

export const getCaseCopy = (params): string => {
  const text = [
    'EC ID: {ecId}',
    'Start date: {startDate}',
    'Probation status: {probationStatus}',
    '',
    'No show history',
    '{noShowsCount} unexcused no shows',
    '',
    '{noShows}',
    '--',
    '',
    'Comments',
    '',
    '{comments}',
    '--',
    '',
    'Warnings',
    '',
    '{warnings}',
    '--',
    '',
    'Inactivity',
    '',
    '{inactivity}',
    '',
    '--',
    '',
  ].join('\n');

  return strFormat(text, params);
};

export const getNoShowsCopy = (noShows, t): string => {
  const shifts = (noShows || [])
    .map((ns) => {
      return ns?.schedules?.map((s) => {
        const date = new Date(ns?.at || 0);
        const from = s.from ? format(new Date(s.from), 'HH:mm') : null;
        const to = s.to ? format(new Date(s.to), 'HH:mm') : null;
        return {
          hub: s.hub_slug || t('hub_unknown'),
          status: ns.warningDeliveredAt
            ? t('warning_sent')
            : ns.reminderDeliveredAt
            ? t('reminder_sent')
            : '',
          copy: `${format(date, 'dd/MM/yyyy')} ${
            from && to ? `${from}-${to}` : t('time_unknown')
          }`,
        };
      });
    })
    .flat();

  const grouped = groupby(shifts, 'hub');
  let copy = '';

  Object.keys(grouped).forEach((hub) => {
    copy = copy.concat(`${hub || ''}\n`);
    grouped[hub].forEach((s) => {
      copy = copy.concat(`${s.copy}${s.status ? ` - ${s.status}` : ''} \n`);
    });
  });

  return copy;
};

export const getCommentsCopy = (comments): string => {
  let copy = '';
  (comments || []).forEach((c) => {
    copy = copy.concat(
      `${c.actor_firstname} ${c.actor_lastname}, ${format(
        new Date(c.created_at || 0),
        'dd/MM/yyyy'
      )}, ${c.description}\n`
    );
  });

  return copy;
};

export const getWarningsCopy = (warnings: Warning[], t): string => {
  let copy = '';
  (warnings || []).forEach((w) => {
    copy = copy.concat(
      `${t('flink_hq')} ${format(
        new Date(w?.delivered_at || 0),
        'dd/MM/yyyy'
      )} - ${t(
        w?.type === 'SHORT_LATENESS'
          ? 'strike_type_short_lateness'
          : w?.type === 'LONG_LATENESS'
          ? 'strike_type_long_lateness'
          : w?.type === 'LATE_SICK_NOTE'
          ? 'strike_type_late_sick_note'
          : 'unexcused_no_show_on',
        {
          date: format(new Date(w?.at || 0), 'dd.MM.yyyy'),
        }
      )}\n`
    );
  });

  return copy;
};

export const getInactivityCopy = (daysInactive, lastActivityAt, t): string => {
  return t('case_copy_last_activity', {
    since: lastActivityAt ? daysInactive : '',
    date: lastActivityAt ? format(new Date(lastActivityAt), 'dd/MM/yyyy') : '-',
  });
};

export const getPeriod = (): { from: Date; to: Date } => {
  // TODO: calendar component will be implemented later
  const nearestMonday = startOfWeek(new Date(), { weekStartsOn: 1 });
  const from = startOfDay(subDays(nearestMonday, 7));
  const to = endOfDay(subDays(nearestMonday, 1));
  return { from, to };
};

export const getPastCalendarWeeks = (
  count: number,
  includeCurrentWeek = false
): {
  cw: number;
  from: Date;
  to: Date;
  isCurrentWeek?: boolean;
}[] => {
  const weeks = Array.from({ length: count }, (_, i) => i + 1);
  const nearestMonday = startOfWeek(new Date(), { weekStartsOn: 1 });

  const calendarWeeks = weeks.map((i) => {
    const monday = subWeeks(nearestMonday, i);
    return {
      cw: getWeek(monday, { weekStartsOn: 1 }),
      from: startOfWeek(monday, { weekStartsOn: 1 }),
      to: endOfWeek(monday, { weekStartsOn: 1 }),
    };
  });

  return includeCurrentWeek
    ? [
        {
          cw: getWeek(nearestMonday, { weekStartsOn: 1 }),
          from: startOfWeek(nearestMonday, { weekStartsOn: 1 }),
          to: endOfWeek(nearestMonday, { weekStartsOn: 1 }),
          isCurrentWeek: true,
        },
        ...calendarWeeks,
      ]
    : calendarWeeks;
};

export const isPerformanceGroupEnabled = (date: Date): boolean => {
  const PERFORMANCE_GROUP_START_DATE = new Date('2024-06-10');
  return (
    isSameDay(date, PERFORMANCE_GROUP_START_DATE) ||
    isAfter(date, PERFORMANCE_GROUP_START_DATE)
  );
};

export const getErrorMessage = (t: any, err: any): string => {
  const validationFields = Object.keys(err?.response?.data?.fields || {});
  const error = err?.response?.data?.error || '';

  const errorMessage = error
    ? validationFields?.length
      ? error.concat(': ').concat(validationFields)
      : error
    : '';

  return err?.response?.status === 404
    ? t('rider_account_not_found_ec_id')
    : error === 'ERROR_INCORRECT_EC_ID'
    ? t('account_import_error_incorrect_ec_id', {
        ecID: err?.response?.data?.fields?.ec_id,
      })
    : t(errorMessage) || t(err.message);
};

export const openSAP = (ecId): void => {
  window.open(`${process.env.REACT_APP_SAP_URL}#/user/${ecId}`, '_blank');
};
