import sdk from '@hubportal/sdk';
import { getInstance } from '@eppo/js-client-sdk';
import { FEATURE_FLAGS, FEATURE_VARIANTS } from '.';

export const getFeedbackMetrics = (): string[] => {
  const eppoClient = getInstance();
  const hub = sdk.getHub()?.slug;

  const values = eppoClient.getJSONAssignment(
    FEATURE_FLAGS.DASHBOARD_VARIABLES,
    hub,
    { hub_slug: hub },
    {}
  );

  return (values as any)?.feedback_metrics?.list || [];
};

export const getNeedHelpButtonConfig = (): string => {
  const eppoClient = getInstance();
  const hub = sdk.getHub()?.slug;

  const values = eppoClient.getJSONAssignment(
    FEATURE_FLAGS.DASHBOARD_VARIABLES,
    hub,
    { hub_slug: hub },
    {}
  );

  return (values as any)?.need_help_button_config;
};

export const getPunchInOverrideReasons = (): string[] => {
  const eppoClient = getInstance();
  const hub = sdk.getHub()?.slug;

  const values = eppoClient.getJSONAssignment(
    FEATURE_FLAGS.DASHBOARD_VARIABLES,
    hub,
    { hub_slug: hub },
    {}
  );

  return (values as any)?.punch_in_override_reasons?.list || [];
};

export const getPunchInOverrideDuration = (): number => {
  const eppoClient = getInstance();
  const hub = sdk.getHub()?.slug;

  const values = eppoClient.getJSONAssignment(
    FEATURE_FLAGS.DASHBOARD_VARIABLES,
    hub,
    { hub_slug: hub },
    {}
  );

  return (values as any)?.punch_in_override_duration_in_minutes || 60;
};

export const getCPHValues = (): { cph: number; age: number }[] => {
  const eppoClient = getInstance();
  const hub = sdk.getHub()?.slug;

  const values = eppoClient.getJSONAssignment(
    FEATURE_FLAGS.DASHBOARD_VARIABLES,
    hub,
    { hub_slug: hub },
    {}
  );

  return ((values as any)?.cph?.list as { cph: number; age: number }[]) || [];
};

export const getTempOfflineReasons = (): string[] => {
  const eppoClient = getInstance();
  const hub = sdk.getHub()?.slug;

  const values = eppoClient.getJSONAssignment(
    FEATURE_FLAGS.DASHBOARD_VARIABLES,
    hub,
    { hub_slug: hub },
    {}
  );

  return (values as any)?.rider_state_temp_offline_reasons?.list || [];
};

export const getExternalAgencies = (): string[] => {
  const eppoClient = getInstance();
  const hub = sdk.getHub()?.slug;

  const values = eppoClient.getJSONAssignment(
    FEATURE_FLAGS.DASHBOARD_VARIABLES,
    hub,
    { hub_slug: hub },
    {}
  );

  return (values as any)?.external_agencies?.list || [];
};

export const getComplianceJobTitles = (): string[] => {
  const eppoClient = getInstance();
  const hub = sdk.getHub()?.slug;

  const values = eppoClient.getJSONAssignment(
    FEATURE_FLAGS.JOB_TITLES,
    hub,
    { hub_slug: hub },
    {}
  );

  return (values as any)?.rider || [];
};

export const isComplianceViewEnabled = (): boolean => {
  const eppoClient = getInstance();
  const hub = sdk.getHub()?.slug;

  const response = eppoClient.getStringAssignment(
    FEATURE_FLAGS.RIDER_PERFORMANCE_VIEW,
    hub,
    { hub_slug: hub },
    FEATURE_VARIANTS.OFF
  );

  return response === FEATURE_VARIANTS.ON;
};

export const isLatenessStrikesGenerationEnabled = (): boolean => {
  const eppoClient = getInstance();
  const hub = sdk.getHub()?.slug;

  const response = eppoClient.getBooleanAssignment(
    FEATURE_FLAGS.LATENESS_STRIKES_GENERATION,
    hub,
    { hub_slug: hub },
    false
  );

  return response;
};

export const isLateSickNoteStrikesGenerationEnabled = (): boolean => {
  const eppoClient = getInstance();
  const hub = sdk.getHub()?.slug;

  const response = eppoClient.getBooleanAssignment(
    FEATURE_FLAGS.LATE_SICK_NOTE_STRIKES_GENERATION,
    hub,
    { hub_slug: hub },
    false
  );

  return response;
};

export const isWorkforceRedesignEnabled = (): boolean => {
  const eppoClient = getInstance();
  const hub = sdk.getHub()?.slug;

  const response = eppoClient.getStringAssignment(
    FEATURE_FLAGS.RIDER_PERFORMANCE_VIEW,
    hub,
    { hub_slug: hub },
    FEATURE_VARIANTS.OFF
  );

  return response === FEATURE_VARIANTS.ON;
};

export const isPunchOnlineEnabled = (): boolean => {
  const eppoClient = getInstance();
  const hub = sdk.getHub()?.slug;

  return eppoClient.getBooleanAssignment(
    FEATURE_FLAGS.PUNCH_ONLINE,
    hub,
    { hub_slug: hub },
    true
  );
};

export const isVehicleAssignmentEnabled = (): boolean => {
  const eppoClient = getInstance();
  const hub = sdk.getHub()?.slug;

  const response = eppoClient.getStringAssignment(
    FEATURE_FLAGS.RIDER_WIDGET_VEHICLE_ASSIGNMENT,
    hub,
    { hub_slug: hub },
    FEATURE_VARIANTS.OFF
  );

  return response === FEATURE_VARIANTS.ON;
};
